import React from "react";

const Header = () => {
    return (
        <div className="header">
            <h1>Androo's Webpage</h1>
        </div>
    )
}

export default Header;